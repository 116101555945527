<template>
  <div class="body">
    <van-nav-bar left-text="返回" left-arrow @click-left="onpage">
      <template #right>
        <van-button class="btn" type="warning" @click="complete('finish')">完成评估</van-button>
      </template>
    </van-nav-bar>
    <div class="from">
      <div class="mycard">
        <van-button class="pingubtn" type="danger">{{ tagname + " - " + mileage_name }}</van-button>
        <div class="text">{{ addTruefalsedatas.title }}</div>
        <div class="view-btns">
          <div class="btn" :class="{active: viewStatus === 1}" @click="viewStatus = 1">查看教具<van-icon name="arrow-down" /></div>
          <div class="btn" :class="{active: viewStatus === 2}" @click="viewStatus = 2">查看评分标准<van-icon name="arrow-down" /></div>
        </div>
        <div class="view-content" v-if="viewStatus">
          <template v-if="viewStatus === 1">
            <div style="line-height: 1.5;">{{ tool || '无' }}</div>
          </template>
          <template v-if="viewStatus === 2">
            <div class="standard">
              <span class="score">0.5分</span>
              <span>{{ half_norm }}</span>
            </div>
            <div class="standard">
              <span class="score">1分</span>
              <span>{{ full_norm }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="choose">
      <div class="checkedbox" v-for="(item, index) in addTruefalsedatas.list" :key="item.id">
        <p ref="box">{{ item.name }}</p>
        <div class="btngroup">
          <button :class="{ trues: active_tab1.indexOf(item.id) > -1 }" @click="choose('true', index, item.id)">
            <van-icon name="success" />
          </button>
          <button :class="{ falses: active_tab2.indexOf(item.id) > -1 }" @click="choose('false', index, item.id)">
            <van-icon name="cross" />
          </button>
        </div>
      </div>
    </div>
    <div class="scorebtn" @click="scoreshow">
      <div class="box"></div>
    </div>
    <div class="fixedcard">
      <div class="buttongroup preTopic" v-if="this.addTruefalsedatas.topic_sort > 1" @click="ona">
        <div style="border: 1px solid #ff8a88">上一题</div>
      </div>

      <!-- <div class="score">
        <span
          v-for="(item, index) in score"
          :key="index"
          :class="{ active: currentIndex === index }"
          @click="getindex(index)"
          >{{ item }}</span
        >
      </div>-->
      <div class="score">
        <span :class="{ active: scorerecord.fullscore === 0, active1: zero }" @click="scoreclick(0)">0分</span>
        <span :class="{ active: scorerecord.fullscore == 0.5, active1: zerofive }" @click="scoreclick(0.5)">0.5分</span>
        <span :class="{ active: scorerecord.fullscore == 1, active1: one }" @click="scoreclick(1)">1分</span>
      </div>
      <div class="buttongroup">
        <div v-if="text === '手动'" style="background: #eea837; color: #fff" @click="manual('手动')">手动打分</div>
        <div v-if="text === '自动'" style="background: #eea837; color: #fff" @click="manual('自动')">恢复自动</div>
        <div style="border: 1px solid #ff8a88" @click="next">下一题</div>
      </div>
    </div>
    <!-- 分数弹框 -->
    <van-popup class="scorebox" v-model="show">
      <div class="calculatescore" v-for="(item, index) in scorearr" :key="index">
        <p :class="{ disabled: item.toprecord_id == 0 && +item.score == 1 }" @click="backSee(item.topic_sort, item.toprecord_id)">{{ item.name }}</p>
        <ul>
          <li :class="{ active: item.score === 0 || item.score === '0.0' }">0分</li>
          <li :class="{ active: item.score == 0.5 }">0.5分</li>
          <li :class="{ active: item.score == 1 }">1分</li>
        </ul>
      </div>
    </van-popup>
    <van-overlay :show="overlayShow" :custom-style="{ background: 'rgba(0,0,0,0)', zIndex: '99' }" @click="overlayTip" :lock-scroll="false" />
  </div>
</template>

<script>
import { Notify, Toast, Overlay, Dialog } from "vant";
import "../../../plugin/element";
import {
  getuid,
  getreviewid,
  gettopicid,
  getlesson_id,
  getltemname,
  getnext,
  setltemname,
  settopicid,
  setlesson_id,
} from "/src/api/userdata.js";
import {
  rightscore,
  recordadd,
  finishAsses,
  topicRecord,
  nextTopic,
  nextContent,
} from "../../../api/index";

export default {
  data() {
    return {
      num: "",
      show: false,
      text: "手动",
      tableshow: false,
      istrue: false,
      isfalse: false,
      radio: "",
      score: ["0分", "0.5分", "1分"],
      currentIndex: "",
      // --------
      addTruefalsedatas: [],
      scorearr: [],
      tagname: "",
      mileage_name: "",
      idarr: {
        uid: "",
        reviewid: "",
        mainid: "",
      },
      activeindex: "",
      savenum: 0,
      scorerecord: {
        fullscore: "",
        fullcoppy: "",
        halfscore: "",
        halfcoppy: "",
      },
      zero: false,
      zerofive: false,
      one: false,
      auto: "",
      nextarr: [],
      num1: "",
      nextreviewid: "",
      nextmainid: "",
      nextlessonid: "",
      nextuid: "",
      active_tab1: [],
      active_tab2: [],
      setarr: [],
      questionid: "",
      toprecord_id: "",
      trecord_id: "",
      topic_sort: 0,
      overlayShow: false,
      isend: true,
      viewStatus: 0,
      tool: '',
      full_norm: '',
      half_norm: ''
    };
  },
  // props: ["addTruefalsedata", "getlist"],
  created() {
    this.getlist();
  },
  components: { "van-overlay": Overlay },
  methods: {
    getlist() {
      this.idarr.uid = getuid();
      this.idarr.reviewid = getreviewid();
      this.idarr.mainid = gettopicid();
      this.tagname = getltemname();
      if (this.$route.query.topic_sort) {
        let preTopicSort = this.$route.query.topic_sort;
        let not = this.$route.query.not;
        let type = this.$route.query.type;
        topicRecord(
          this.idarr.uid,
          preTopicSort,
          this.idarr.reviewid,
          this.idarr.mainid,
          type
        ).then((res) => {
          if (res.code === 9990) {
            Notify({ type: "danger", message: res.msg });
            this.$router.replace({
              name: "login",
            });
          }
          if (res.code != 1) {
            return Notify({ type: "danger", message: res.msg });
          }
          this.addTruefalsedatas = res.data.topic_info;
          this.mileage_name = this.addTruefalsedatas.mileage_name;
          this.questionid = this.addTruefalsedatas.id;
          this.num = this.addTruefalsedatas.main_id;
          this.trecord_id = res.data.trecord_id;
          this.toprecord_id = res.data.id;
          this.topic_sort = this.addTruefalsedatas.topic_sort;
          this.scorerecord.fullscore = res.data.score;
          this.tool = addTruefalsedatas.tool;
          this.full_norm = addTruefalsedatas.full_norm;
          this.half_norm = addTruefalsedatas.half_norm;
          let answerArr = res.data.son_toprecrd;
          res.data.son_toprecrd.map((e, index) => {
            this.setarr.push({
              id: e.id,
              topic_id: e.topic_id,
              answer: e.answer,
            });
            if (e.answer * 1 === 1) {
              this.active_tab1.push(e.topic_id);
            }
            if (e.answer * 1 === 0) {
              this.active_tab2.push(e.topic_id);
            }
          });
          if (not == 1) {
            this.overlayShow = true;
          }
          this.getNextMain()
        });
      } else {
        let params = {
          uid: this.idarr.uid,
          review_id: this.idarr.reviewid,
          main_id: this.idarr.mainid,
          is_rec: this.$route.query.is_rec,
          trec_id: this.$route.query.trec_id,
          topic_id: this.$route.query.topic_id,
        };
        nextContent(params).then((res) => {
          if (res.code === 9990) {
            Notify({ type: "danger", message: res.msg });
            this.$router.replace({
              name: "login",
            });
          }
          this.addTruefalsedatas = res.data;
          this.mileage_name = this.addTruefalsedatas.mileage_name;
          this.questionid = this.addTruefalsedatas.id;
          this.num = this.addTruefalsedatas.main_id;
          this.trecord_id = this.addTruefalsedatas.trecord_id;
          this.topic_sort = this.addTruefalsedatas.topic_sort;
          this.scorerecord.fullscore = res.data.score ? res.data.score : '';
          this.tool = res.data.tool;
          this.full_norm = res.data.full_norm;
          this.half_norm = res.data.half_norm;
          for (let i = 0; i < this.addTruefalsedatas.list.length; i++) {
            const element = this.addTruefalsedatas.list[i].id;
            this.setarr[i] = { id: "", topic_id: element, answer: '' };
          }
          this.getNextMain()
        });
      }
    },
    async getNextMain() {
      let thisMainIndex = "";
      const uid = getuid();
      const id = getreviewid();
      let pathArr = [
        "must-topic/main-list",
        "must-topic/skill-list",
        "must-topic/feel-list",
        "must-topic/rim-list",
      ];
      let params = {
        uid,
        review_id: id,
      };
      const res = await this.$http.get(pathArr[this.$route.query.eva_type], {
        params: params,
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.nextarr = res.data.list;
      for (let i = 0; i < this.nextarr.length; i++) {
        const element = this.nextarr[i].main_id;
        if (this.num == this.nextarr[this.nextarr.length - 1].main_id) {
          this.nextname = "最后一个维度了";
        }
        if (this.num == element) {
          thisMainIndex = i;
        } else if (this.num != element && this.nextarr[i].status != 1) {
          this.isend = false;
        }
        if (
          thisMainIndex === 0 &&
          i > thisMainIndex &&
          this.nextarr[i].status != 1
        ) {
          this.nextmainid = this.nextarr[i].main_id;
          this.nextname = this.nextarr[i].main_name;
          this.nextlessonid = this.nextarr[i].lesson_id;
          return false;
        }
        if (thisMainIndex && i > thisMainIndex && this.nextarr[i].status != 1) {
          this.nextmainid = this.nextarr[i].main_id;
          this.nextname = this.nextarr[i].main_name;
          this.nextlessonid = this.nextarr[i].lesson_id;
          return false;
        } else if (
          thisMainIndex !== '' &&
          i > thisMainIndex &&
          this.nextarr[i].status == 1
        ) {
          this.nextname = "最后一个维度了";
        }
      }
    },

    scoreclick(val) {
      if (this.auto == "手动") {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        if (val === 0) {
          this.zero = true;
          this.zerofive = false;
          this.one = false;
          this.scorerecord.fullscore = 0;
        } else if (val === 0.5) {
          this.zerofive = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 0.5;
        } else if (val === 1) {
          this.one = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 1;
        }
      }
    },
    next() {
      this.complete("add");
    },
    ona() {
      this.complete("jian");
    },
    getindex(val) {
      this.currentIndex = val;
    },
    onpage() {
      this.$router.replace({
        name: "startpingu",
        query: {
          ass_num: this.$route.query.ass_num,
          eva_type: this.$route.query.eva_type,
        },
      }).then(() => {
        this.$router.go(-1)
      });
    },
    async getnexts() {
      const uid = getuid();
      const id = getreviewid();
      let pathArr = [
        "must-topic/main-list",
        "must-topic/skill-list",
        "must-topic/feel-list",
        "must-topic/rim-list",
      ];
      let params = {
        uid,
        review_id: id,
      };
      const res = await this.$http.get(pathArr[this.$route.query.eva_type], {
        params: params,
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.nextarr = res.data;
      const indexid = Number(getnext());
      this.num1 = indexid;
    },
    complete(val) {
      let uid = getuid();
      let reviewid = getreviewid();
      let mainid = gettopicid();
      let lesson_id = getlesson_id();
      if (val == "add") {
        rightscore(uid, reviewid, mainid).then((res) => {
          this.allTopic = res.data;
          let lastId = this.allTopic[this.allTopic.length - 1].id;
          if (this.scorerecord.fullscore === "") {
            Dialog.alert({
              title: "提示",
              message: "请作答当前题目",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              // on close
            });
            return false;
          }
          if (this.scorerecord.fullscore === 0) {
            this.$dialog
              .confirm({
                title: "提示",
                message: `${this.tagname + this.mileage_name}未得分，即将结束本维度测评，是否确认？`,
                confirmButtonColor: "#fff",
                messageAlign: "left",
                getContainer: ".body",
              })
              .then(() => {
                let data = {
                  trecord_id: this.trecord_id,
                  toprecord_id: this.toprecord_id,
                  uid: uid,
                  review_id: reviewid,
                  main_id: mainid,
                  topic_id: this.questionid,
                  score: this.scorerecord.fullscore,
                  ans_record: JSON.stringify(this.setarr),
                };
                recordadd(data).then((res) => {
                  if (res.code == "1") {
                    this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                    Notify({ type: "success", message: res.msg });
                  }
                });
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            return false
          }
          if (this.questionid == lastId) {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            recordadd(data).then((res) => {
              if (res.code == "1") {
                this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                Notify({ type: "success", message: res.msg });
              }
            });
          } else {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            recordadd(data).then((res) => {
              if (res.code == "1") {
                let params = {
                  uid: uid,
                  review_id: reviewid,
                  main_id: mainid,
                  topic_sort: this.topic_sort,
                };
                nextTopic(params).then((res) => {
                  if (res.code == 9100) {
                    this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                    return;
                  }
                  if (res.data.topic_type == 1) {
                    this.$router.replace({
                      name: "addtable",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      },
                    });
                  } else if (res.data.topic_type == 2) {
                    this.$router.replace({
                      name: "choosepicture",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      },
                    });
                  } else if (res.data.topic_type == 3) {
                    this.$router.replace({
                      name: "addTruefalse",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      },
                    });
                    window.location.reload();
                  }
                });
                Notify({ type: "success", message: res.msg });
              }
            });
          }
        });
      } else if (val == "jian") {
        let preTopicSort = this.topic_sort - 1;
        topicRecord(uid, preTopicSort, reviewid, mainid, 1).then((res) => {
          if (res.code != 1) {
            return Notify({ type: "danger", message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.replace({
              name: "addtable",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
            window.location.reload();
          }
        });
      } else if (val == "finish") {
        if (this.scorerecord.fullscore == "") {
          this.scorerecord.fullscore = 0;
        }
        let data = {
          trecord_id: this.trecord_id,
          toprecord_id: this.toprecord_id,
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          topic_id: this.questionid,
          score: this.scorerecord.fullscore,
          ans_record: JSON.stringify(this.setarr),
        };
        recordadd(data).then((res) => {
          if (res.code == "1") {
            this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
            Notify({ type: "success", message: res.msg });
          }
        });
      }
    },
    // 添加答题记录
    // nextTopic(uid, reviewid, mainid) {
    //   if (this.scorerecord.fullscore == "") {
    //     this.scorerecord.fullscore = 0;
    //   }
    //   let data = {
    //     trecord_id: this.trecord_id,
    //     toprecord_id: this.toprecord_id,
    //     uid: uid,
    //     review_id: reviewid,
    //     main_id: mainid,
    //     topic_id: this.questionid,
    //     score: this.scorerecord.fullscore,
    //     ans_record: JSON.stringify(this.setarr),
    //   };
    //   recordadd(data).then((res) => {
    //     Notify(res.msg);
    //   });
    // },
    // 下一维度
    nextMainConfirm(uid, reviewid, mainid, lesson_id) {
      let data = {
        uid: uid,
        review_id: reviewid,
        main_id: mainid,
        lesson_id,
      };
      finishAsses(data).then((res) => {
        Notify({ type: "success", message: res.msg });
        if (this.nextname == "最后一个维度了") {
          if (this.isend) {
            Toast.success({
              duration: 3000,
              forbidClick: true,
              message: "恭喜你，完成评估",
              onClose: () => {
                this.$router.replace({
                  name: "startpingu",
                  query: {
                    ass_num: this.$route.query.ass_num,
                    eva_type: this.$route.query.eva_type,
                  },
                }).then(() => {
                  this.$router.go(-1)
                });
              },
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "本维度已完成评估，请选择未完成的维度继续作答",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              this.$router.replace({
                name: "startpingu",
                query: {
                  ass_num: this.$route.query.ass_num,
                  eva_type: this.$route.query.eva_type,
                },
              }).then(() => {
                this.$router.go(-1)
              });
            });
          }
        } else {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "本维度的测评已结束，下一个测评维度是" +
                '<span style="color:red">' +
                this.nextname +
                "</span>",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              confirmButtonText: "继续评估",
              cancelButtonText: "手动调整",
              getContainer: ".body",
            })
            .then(() => {
              settopicid(this.nextmainid);
              setltemname(this.nextname);
              setlesson_id(this.nextlessonid);
              let nextuid = getuid();
              let nextreviewid = getreviewid();
              let params = {
                uid: nextuid,
                review_id: nextreviewid,
                main_id: this.nextmainid,
                topic_sort: 0,
              };
              nextTopic(params).then((res) => {
                if (res.data.topic_type == 1) {
                  this.$router.replace({
                    name: "addtable",
                    query: {
                      ass_num: this.$route.query.ass_num,
                      eva_type: this.$route.query.eva_type,
                      is_rec: res.data.is_rec,
                      trec_id: res.data.trec_id,
                      topic_id: res.data.topic_id,
                      topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                    },
                  });
                } else if (res.data.topic_type == 2) {
                  this.$router.replace({
                    name: "choosepicture",
                    query: {
                      ass_num: this.$route.query.ass_num,
                      eva_type: this.$route.query.eva_type,
                      is_rec: res.data.is_rec,
                      trec_id: res.data.trec_id,
                      topic_id: res.data.topic_id,
                      topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                    },
                  });
                } else if (res.data.topic_type == 3) {
                  this.$router.replace({
                    name: "addTruefalse",
                    query: {
                      ass_num: this.$route.query.ass_num,
                      eva_type: this.$route.query.eva_type,
                      is_rec: res.data.is_rec,
                      trec_id: res.data.trec_id,
                      topic_id: res.data.topic_id,
                      topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                    },
                  });
                  window.location.reload();
                }
              });
            })
            .catch(() => {
              this.$router.replace({
                name: "startpingu",
                query: {
                  ass_num: this.$route.query.ass_num,
                  eva_type: this.$route.query.eva_type,
                },
              }).then(() => {
                this.$router.go(-1)
              });
            });
        }
      });
    },

    scoreshow() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.show = true;
      rightscore(uid, reviewid, mainid).then((res) => {
        this.scorearr = res.data;
      });
    },
    manual(val) {
      this.auto = val;
      if (val === "手动") {
        const coppy = JSON.parse(JSON.stringify(this.scorerecord.fullscore));
        this.scorerecord.fullcoppy = coppy;
        this.scorerecord.fullscore = "";
        this.text = "自动";
        this.zero = false;
        this.zerofive = false;
        this.one = false;
      } else {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        this.scorerecord.fullscore = this.scorerecord.fullcoppy;
        this.text = "手动";
      }
    },
    deltablemodel() {
      this.tableshow = false;
    },
    opentable() {
      this.tableshow = !this.tableshow;
    },
    deleteitem() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "删除项目记录后会影响本题得分，确定删除吗？",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          alert("确定");
          // on confirm
        })
        .catch(() => {
          alert("取消");
          // on cancel
        });
    },
    choose(val, index, itemid) {
      const fullmark = Number(this.addTruefalsedatas.full_mark);
      const halfmark = Number(this.addTruefalsedatas.half_mark);
      console.log(itemid);
      let csskey = this.active_tab1.indexOf(itemid);
      let csskey2 = this.active_tab2.indexOf(itemid);
      console.log(csskey, csskey2);
      if (val == "false") {
        this.isfalse = !this.isfalse;
        this.istrue = false;
        if (csskey2 > -1) {
          // 已选中,点击取消
          this.active_tab2.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab2.splice(index, 1);
            }
          });
          let obj = this.setarr.find(e => e.topic_id * 1 === itemid * 1)
          if (obj) {
            obj.answer = '';
          } else {
            this.setarr.push({
              id: '',
              topic_id: itemid,
              answer: '',
            })
          }
        } else {
          this.active_tab2.push(itemid);
          this.active_tab1.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab1.splice(index, 1);
            }
          });
          let obj = this.setarr.find(e => e.topic_id * 1 === itemid * 1)
          if (obj) {
            obj.answer = 0;
          } else {
            this.setarr.push({
              id: '',
              topic_id: itemid,
              answer: 0,
            })
          }
        }
      }
      if (val == "true") {
        if (csskey > -1) {
          // 已选中,点击取消
          this.active_tab1.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab1.splice(index, 1);
            }
          });
          let obj = this.setarr.find(e => e.topic_id * 1 === itemid * 1)
          if (obj) {
            obj.answer = '';
          } else {
            this.setarr.push({
              id: '',
              topic_id: itemid,
              answer: '',
            })
          }
        } else {
          this.active_tab1.push(itemid);
          this.active_tab2.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab2.splice(index, 1);
            }
          });
          let obj = this.setarr.find(e => e.topic_id * 1 === itemid * 1)
          if (obj) {
            obj.answer = 1;
          } else {
            this.setarr.push({
              id: '',
              topic_id: itemid,
              answer: 1,
            })
          }
        }
        this.istrue = !this.istrue;
        this.isfalse = false;
        this.activeindex = index;
      }
      this.savenum = this.active_tab1.length;
      if (this.savenum >= fullmark) {
        this.scorerecord.fullscore = 1;
        this.scorerecord.fullcoppy = 1;
      } else if (this.savenum < fullmark && this.savenum >= halfmark) {
        this.scorerecord.fullscore = 0.5;
        this.scorerecord.fullcoppy = 0.5;
        this.isok = true;
      } else {
        this.scorerecord.fullscore = 0;
        this.scorerecord.fullcoppy = 0;
        this.isok = true;
      }
    },
    // 回看
    backSee(topic_sort, toprecord_id) {
      if (toprecord_id != 0) {
        let uid = getuid();
        let reviewid = getreviewid();
        let mainid = gettopicid();
        topicRecord(uid, topic_sort, reviewid, mainid, 0).then((res) => {
          if (res.code != 1) {
            return Notify({ type: "danger", message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.replace({
              name: "addtable",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
            window.location.reload();
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
            window.location.reload();
          }
        });
      }
    },
    overlayTip() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "当前状态可查看，但无法编辑，点击确认即可返回维度列表",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          this.$router.replace({
            name: "startpingu",
            query: {
              ass_num: this.$route.query.ass_num,
              eva_type: this.$route.query.eva_type,
            },
          }).then(() => {
            this.$router.go(-1)
          });
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  min-height: 100vh;
  background: url(../src/img/bj.png) top left/100% auto no-repeat;
  padding-bottom: 0.7rem;
  button {
    border: none;
  }
  .van-nav-bar {
    background: none;
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
    .btn {
      width: 1rem;
      height: 0.34rem;
      background: #f14947;
      border-radius: 0.06rem;
      font-size: 0.14rem;
      border: none;
    }
  }
  .from {
    padding: 0 16px;
  }
  .mycard {
    // width: 95%;
    padding: 0.13rem 0.16rem;
    margin: 0 auto;
    font-size: 0.16rem;
    background: #ffffff;
    border-radius: 0.06rem;
    margin-top: 0.1rem;
    box-shadow: 0 0 0.1rem 0.01rem rgba(157, 4, 4, 0.05);
    box-sizing: border-box;
    .pingubtn {
      height: 0.34rem;
      background: #eea837;
      border-radius: 0.06rem;
      font-size: 0.16rem;
      overflow: hidden;
      float: left;
      margin-right: 0.16rem;
    }
    .text {
      color: #333333;
      line-height: 0.35rem;
      letter-spacing: 0.01rem;
    }
    .view-btns {
      display: flex;
      align-items: center;
      margin-top: 0.12rem;
      .btn {
        line-height: 0.26rem;
        background: #ffeeee;
        border-radius: 0.14rem;
        font-size: 0.13rem;
        color: #f14947;
        padding: 0 0.1rem;
        margin-right: 0.12rem;
        position: relative;
        &.active::after {
          content: "";
          display: block;
          width: 0.12rem;
          height: 0.12rem;
          background: #ffeeee;
          border-radius: 0.02rem;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          z-index: 1;
        }
      }
    }
    .view-content {
      display: flex;
      flex-wrap: wrap;
      background: #f8f8f8;
      border-radius: 0.06rem;
      padding: 0.08rem 0.15rem;
      margin-top: 0.1rem;
      font-size: 0.13rem;
      color: #666;
      line-height: 1;
      .standard {
        margin: 0.05rem 0.3rem 0.05rem 0;
        display: flex;
        align-items: center;
        .score {
          display: inline-block;
          line-height: 0.2rem;
          background: #ffdb9f;
          border-radius: 0.1rem;
          padding: 0 0.07rem;
          font-size: 0.12rem;
          color: #674000;
          margin-right: 0.07rem;
        }
        span {
          vertical-align: sub;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .choose {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .checkedbox {
      height: 0.32rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.18rem;
      font-size: 0;
      p {
        width: 1.6rem;
        font-size: 0.16rem;
        color: #333333;
        margin: 0 0.34rem 0 0;
      }
      button {
        width: 0.48rem;
        height: 0.32rem;
        border-radius: 0.06rem;
        border: 1px solid #cccccc;
        color: #999999;
        background: #fff;
        font-size: 0.2rem;
        box-sizing: border-box;
        padding: 0;
      }
      button:nth-child(1) {
        margin-right: 0.1rem;
      }
      .trues {
        background: #fff3f3;
        border: 1px solid #ff8a88;
        color: red;
      }
      .falses {
        background: #fff3f3;
        border: 1px solid #ff8a88;
        color: red;
      }
    }
  }
  .scorebtn {
    height: 0.44rem;
    position: fixed;
    right: 0;
    top: 70%;
    display: flex;
    .box {
      width: 0.44rem;
      height: 0.44rem;
      background: #ffecec;
      box-shadow: 0 0 0.06rem 0.01rem rgba(110, 8, 8, 0.23);
      &::before {
        position: absolute;
        top: 0.06rem;
        left: -0.17rem;
        content: "";
        width: 0.32rem;
        height: 0.32rem;
        background: #ffecec;
        transform: rotate(135deg);
        box-shadow: 0 0 0.06rem 0.01rem rgba(110, 8, 8, 0.23);
      }
      &::after {
        content: "分数";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.44rem;
        height: 0.44rem;
        background: #ffecec;
        font-size: 0.15rem;
        color: #f14947;
        line-height: 0.44rem;
        text-align: center;
      }
    }
  }
  .scorebox {
    height: 100%;
    background: #ffffff;
    padding: 0.24rem 0.24rem 0.08rem;
    position: fixed;
    border-radius: 0.2rem 0.2rem 0 0;
    top: 0;
    right: 0;
    left: auto;
    transform: none;
  }
  .calculatescore {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
    p {
      color: #f14947;
      font-size: 0.16rem;
      margin: 0;
      width: 0.34rem;
      &.disabled {
        color: #999;
      }
    }
    ul {
      display: flex;
      li {
        width: 0.64rem;
        height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        text-align: center;
        line-height: 0.34rem;
        margin-left: 0.2rem;
      }
      li.active {
        background: #f1b207;
        color: #fff;
        border: 1px solid #f1b207;
      }
    }
  }
  .calculatescore:last-child {
    margin-bottom: 0.4rem;
  }
  .fixedcard {
    width: 100%;
    height: 0.7rem;
    background: #ffffff;
    box-shadow: 0 0 0.06rem 0.01rem rgba(157, 4, 4, 0.16);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 55;
    box-sizing: border-box;
    padding: 0 0.2rem;
    .score {
      font-size: 0;
      span {
        display: inline-block;
        width: 0.56rem;
        height: 0.34rem;
        text-align: center;
        line-height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        margin-left: 0.1rem;
      }
      .active {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
      .active1 {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
    }
    .buttongroup {
      display: flex;
      div {
        width: 0.96rem;
        height: 0.34rem;
        border-radius: 0.17rem;
        text-align: center;
        line-height: 0.34rem;
        font-size: 0.16rem;
        margin-left: 0.11rem;
      }
    }
    .preTopic {
      height: fit-content;
      position: absolute;
      left: 0.2rem;
      margin: auto;
      div {
        margin-left: 0;
      }
    }
  }
  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  /deep/ .van-dialog {
    width: 4.06rem;
    padding-bottom: 0.2rem;
    .van-dialog__header {
      text-align: left;
      padding: 0.2rem 0.24rem 0;
      color: #666;
    }
    .van-dialog__header::before {
      content: "!";
      display: inline-block;
      width: 0.18rem;
      height: 0.18rem;
      border-radius: 50%;
      text-align: center;
      font-size: 0.18rem;
      vertical-align: text-bottom;
      margin-right: 0.06rem;
      line-height: 0.2rem;
      padding: 0 0 0.01rem 0.01rem;
      background: #ffaa20;
      color: #fff;
    }
    .van-dialog__message--has-title {
      padding-top: 0.1rem;
      padding-bottom: 0.27rem;
      font-size: 0.14rem;
      line-height: 0.3rem;
    }
    .van-dialog__footer {
      justify-content: center;
      overflow: initial;
      .van-button {
        width: 0.94rem;
        height: 0.34rem;
        flex: none;
        border-radius: 0.06rem;
        margin: 0 0.13rem;
        font-size: 0.16rem;
      }
      .van-dialog__cancel {
        border: 1px solid #b2b2b2;
        color: #707070;
      }
      .van-dialog__confirm {
        background: #fa4b52;
        color: #fff;
      }
    }
    [class*="van-hairline"]::after {
      border: none;
    }
  }
}
@media only screen and (min-width: 1024px) {
  .body {
    .choose {
      width: 95%;
      justify-content: flex-start;
      .checkedbox {
        margin-right: 0.5rem;
        p {
          margin-right: 0.16rem;
        }
        button:nth-child(1) {
          margin-right: 0.16rem;
        }
      }
      .checkedbox:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>